<template>
    <div class="account">
        <web-view :url="dashboard" />
    </div>
</template>

<script>
    import WebView from '../components/WebView.vue'
    export default {
        components: {
            WebView
        },
        data() {
            return {
                dashboard: process.env.VUE_APP_AUTH_URL + '/dashboard'
            }
        }
    }
</script>

<style></style>
